import React from "react";

import { showTexts } from "../../helpers";
import { TopBarMobile, TopBarWeb } from "./Components";

import {
  TopBarContainer,
  TopBarContent,
  TopBarHolder,
  TopBarHolderMobile,
} from "./style";

interface IOptionMenu {
  routeName: string;
  title: string;
}

interface ITopBarProps {
  internationalization: "enUS" | "es" | "ptBR";
  optionsMenu: IOptionMenu[];
  optionsMenuRedirectExternal: string;
  productName: string;
  userLogged: boolean;
}

export const TopBar: React.FunctionComponent<ITopBarProps> = (props) => {
  const HTTP_LOGIN_REDIRECT = process.env.REACT_APP_LOGIN_REDIRECT || "/login";

  const showTextsi18n = showTexts(
    props.internationalization,
    props.optionsMenu,
    props.productName
  );

  const [openMenu, setOpenMenu] = React.useState(false);
  const [widthDimensions, setWidthDimensions] = React.useState(0);

  const getElementHeight = (elementId: string) => {
    const element = document.getElementById(elementId);
    if (!element) return 0;

    const computedStyle = window.getComputedStyle(element);
    const height =
      element.clientHeight +
      parseInt(computedStyle.marginBottom, 10) +
      parseInt(computedStyle.marginTop, 10);

    return height;
  };

  const updateDimensions = () => {
    const width = window.outerWidth;
    setWidthDimensions(width);
  };

  const handleOpenMenu = React.useCallback(() => {
    if (openMenu) {
      setOpenMenu(false);
    } else {
      setOpenMenu(true);
    }
  }, [openMenu]);

  const handleUserLogged = React.useCallback(() => {
    if (props.userLogged) {
      window.location.assign("/provedores");
    } else {
      window.location.assign(HTTP_LOGIN_REDIRECT);
    }
  }, [props.userLogged, HTTP_LOGIN_REDIRECT]);

  const handleNavigation = React.useCallback(
    (routeName: string) => {
      const homeHeight = 0;
      const whenToUseHeight = homeHeight + getElementHeight("home");
      const howToUseHeight = whenToUseHeight + getElementHeight("whenToUse");
      const frequentlyQuestionsHeight =
        howToUseHeight + getElementHeight("howToUse");
      const plansHeight =
        frequentlyQuestionsHeight + getElementHeight("frequentlyQuestions");

      const routePosition: number | boolean =
        routeName === "#home"
          ? homeHeight
          : routeName === "#whenToUse"
          ? whenToUseHeight
          : routeName === "#howToUse"
          ? howToUseHeight
          : routeName === "#frequentlyQuestions"
          ? frequentlyQuestionsHeight * 0.99
          : routeName === "#plans"
          ? plansHeight * 0.99
          : false;

      if (routePosition !== false) {
        window.scrollTo({
          top: routePosition,
          behavior: "smooth",
        });
      }

      setOpenMenu(false);
      if (props.optionsMenuRedirectExternal !== null) {
        localStorage.removeItem("@conecta:topbar-redirect-scroll");
      }
    },
    [props.optionsMenuRedirectExternal]
  );

  React.useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (props.optionsMenuRedirectExternal !== null) {
        handleNavigation(String(props.optionsMenuRedirectExternal));
      }
    }, 400);
    () => clearTimeout(timeout);
  }, [props.optionsMenuRedirectExternal, handleNavigation]);

  return (
    <TopBarContainer>
      <TopBarContent>
        {widthDimensions <= 520 ? (
          <TopBarHolderMobile className="topbarholder">
            <TopBarMobile
              onPress={(routeName) => handleNavigation(routeName)}
              onPressMenu={handleOpenMenu}
              onPressButtonRight={handleUserLogged}
              openMenu={openMenu}
              optionsMenu={showTextsi18n.optionsMenu}
              productName={showTextsi18n.productName}
              titleMenuMobile={showTextsi18n.titleMenuMobile}
              titleMenuMobileLogin={showTextsi18n.titleMenuMobileLogin}
              typeButton="label"
              userLogged={props.userLogged}
            />
          </TopBarHolderMobile>
        ) : (
          <TopBarHolder className="topbarholder">
            <TopBarWeb
              buttonTopRight={showTextsi18n.buttonTopRight}
              onPress={(routeName) => handleNavigation(routeName)}
              onPressButtonRight={handleUserLogged}
              optionsMenu={showTextsi18n.optionsMenu}
              productName={showTextsi18n.productName}
            />
          </TopBarHolder>
        )}
      </TopBarContent>
    </TopBarContainer>
  );
};
