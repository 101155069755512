import { enUS, es, ptBR } from "../../i18n";

interface IOptionMenu {
  routeName: string;
  title: string;
}

type TypeInternationalization = "enUS" | "es" | "ptBR";

const showTexts = (
  internationalization: TypeInternationalization,
  optionsMenuTabBar: IOptionMenu[],
  productNameTabBar: string
) => {
  const i18n = { enUS, es, ptBR };

  return {
    buttonTopRight: i18n[internationalization].buttonTopRight,
    optionsMenu:
      optionsMenuTabBar?.length > 0 && optionsMenuTabBar !== null
        ? optionsMenuTabBar
        : i18n[internationalization].menu,
    productName:
      productNameTabBar?.length > 0 && productNameTabBar !== null
        ? productNameTabBar
        : i18n[internationalization].productName,
    titleMenuMobile: i18n[internationalization].titleMenuMobile,
    titleMenuMobileLogin: i18n[internationalization].titleMenuMobileLogin,
  };
};

export default showTexts;
