import { TopBar } from "./Modules";
import { IOptionMenu } from "./Modules/TopBar/Components/TopBarMobile";

type TypeInternationalization = "enUS" | "es" | "ptBR";

export default function Root() {
  const internationalization = localStorage.getItem(
    "@conecta:internationalization"
  ) as TypeInternationalization;

  const userLogged = JSON.parse(localStorage.getItem("@conecta:user"));

  const optionsMenu = JSON.parse(
    localStorage.getItem("@conecta:topbar-options-menu")
  ) as IOptionMenu[];

  const optionsMenuRedirectExternal = localStorage.getItem(
    "@conecta:topbar-redirect-scroll"
  );

  const productName = localStorage.getItem(
    "@conecta:topbar-product-name"
  ) as string;

  return (
    <TopBar
      internationalization={
        internationalization?.length > 0 ? internationalization : "ptBR"
      }
      optionsMenu={optionsMenu}
      optionsMenuRedirectExternal={optionsMenuRedirectExternal}
      productName={productName}
      userLogged={userLogged === null ? false : true}
    />
  );
}
