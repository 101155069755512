import styled from "styled-components";

interface ITopBarMobileContainerProps {
  openMenu: boolean;
}

export const TopBarMobileContainerMenu = styled.div``;

export const TopBarMobileContentMenu = styled.div<ITopBarMobileContainerProps>`
  width: 100%;
  min-width: 20rem;
  height: 100vh;
  transform: ${({ openMenu }) =>
    openMenu ? "translateX(0)" : "translateX(-100%)"};
  transition: transform 0.3s ease-in-out;
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
`;
