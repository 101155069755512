import styled from "styled-components";

export const TopBarContainer = styled.div`
  width: 100%;
  margin-bottom: 151px;
  @media only screen and (max-width: 520px) {
    margin-bottom: 5.625rem;
  }
`;

export const TopBarContent = styled.div`
  width: 100%;
  height: auto;
  position: fixed;
  z-index: 1000;
  background-color: #ffffff;
  top: 0;
`;
export const TopBarHolder = styled.div`
  & > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) {
    background-image: url("https://conecta-assets-prd.telemedicinaeinstein.com.br/logos/svg/albert-einstein.svg");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
  }
`;

export const TopBarHolderMobile = styled.div`
  div:nth-child(2) > div:nth-child(1) div:nth-child(1) div:nth-child(1) {
    background-image: url("https://conecta-assets-prd.telemedicinaeinstein.com.br/logos/svg/albert-einstein.svg");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
  }
`;
