import React from "react";
import { VirtualService } from "zera";

export interface IOptionMenu {
  routeName: string;
  title: string;
}

interface ITopBarMobileProps {
  buttonTopRight: string;
  onPressButtonRight: () => void;
  onPress: (routeName: string) => void;
  optionsMenu: IOptionMenu[];
  productName: string;
}

export const TopBarWeb: React.FunctionComponent<ITopBarMobileProps> = (
  props
) => {
  return (
    <React.Fragment>
      <VirtualService
        buttonTopRight={props.buttonTopRight}
        onPressButtonRight={props.onPressButtonRight}
        onPress={props.onPress}
        optionsMenu={props.optionsMenu}
        productName={props.productName}
      />
    </React.Fragment>
  );
};
