import React from "react";
import { MarketplaceTopBar, VirtualEmergencyService } from "zera";

import { TopBarMobileContainerMenu, TopBarMobileContentMenu } from "./style";

export interface IOptionMenu {
  routeName: string;
  title: string;
}

interface ITopBarMobileProps {
  onPressButtonRight: () => void;
  onPressMenu: () => void;
  onPress: (routeName: string) => void;
  optionsMenu: IOptionMenu[];
  openMenu: boolean;
  productName: string;
  titleMenuMobile: string;
  titleMenuMobileLogin: string;
  typeButton: string;
  userLogged: boolean;
}

export const TopBarMobile: React.FunctionComponent<ITopBarMobileProps> = (
  props
) => {
  return (
    <TopBarMobileContainerMenu>
      <MarketplaceTopBar
        label={props.titleMenuMobileLogin}
        onPressButtonRight={props.onPressButtonRight}
        onPressMenu={props.onPressMenu}
        productName={props.productName}
        type="Menu-On"
        typeButton={
          props.typeButton !== null
            ? props.typeButton === "label"
              ? "label"
              : "icon"
            : "label"
        }
        userLogged={props.userLogged}
        width="100%"
      />
      <TopBarMobileContentMenu openMenu={props.openMenu}>
        <VirtualEmergencyService
          onPressCloseMenu={props.onPressMenu}
          onPress={props.onPress}
          optionsMenu={props.optionsMenu}
          titleHeader={props.titleMenuMobile}
          width="100%"
        />
      </TopBarMobileContentMenu>
    </TopBarMobileContainerMenu>
  );
};
